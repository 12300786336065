jQuery.noConflict()(function($) {
    "use strict";
    /*if ($(window).height() < 700) {
        //$('#oi_featuder_posts').css('margin-top', '20px');
    };*/


    /*
    $('.oi_slider').flexslider({
        prevText: "", //String: Set the text for the "previous" directionNav item
        nextText: "",
        animation: "fade",
        useCSS: true,
        controlNav: false,
        animationLoop: true,
        slideshow: true,
        slideshowSpeed: 3000,
        pauseOnHover: true,
        start: function(slider) {
            slider.removeClass('oi_flex_loading');
        }
    });
    */
    $(window).load(function() {
        /*
        var wh = ((window.innerHeight) / 2);
        var hh = ($(".oi_head_holder").outerHeight()) / 2;
        var lh = ($(".oi_left_text_area").outerHeight()) / 2;
        var lp = $(".oi_logo_place").outerHeight() / 2;
        var rh = ($(".oi_right_text_area:not(.oi_inner_soc)").outerHeight()) / 2;
        var rhs = ($(".oi_right_text_area.oi_inner_soc").outerHeight()) / 2;
        if ($('body').width() > 640) {

            $(".oi_head_holder:not(.oi_inner)").css({
                'margin-top': (wh - hh)
            })

            $(".oi_left_text_area").css({
                'margin-top': (lp - lh)
            })
            $(".oi_right_text_area:not(.oi_inner)").css({
                'margin-top': (lp - rh)
            })
            $(".oi_right_text_area.oi_inner:not(.oi_inner_soc)").css({
                'margin-top': (hh - rh)
            })
            $(".oi_right_text_area.oi_inner.oi_inner_soc").css({
                'margin-top': (hh - rhs)
            })
            $('#oi_container:not(.oi_inner)').css({
                'margin-top': (wh * 2)
            })
            $('#oi_container.oi_inner').css({
                'margin-top': (hh * 2) + 60
            })
            $('#scroll_to_content a').click(function(e) {
                e.preventDefault();
                $('body,html').animate({
                    scrollTop: $('#oi_container').offset().top
                }, 400);
                return false;
            });
        };
		*/
		

		
    });

    /*if ($('body').width() > 640) {

        /!*$(window).load(function() {
            if (($("body").height() - $(window).height()) > 300) {
                var stickyNavTop = $('.oi_head_holder').offset().top + $(".oi_head_holder .row").outerHeight();
                $(window).scroll(function() {
                    if ($(this).scrollTop() > stickyNavTop) {
                        $('.oi_st_menu_holder').fadeIn('fast');
                    } else {
                        $('.oi_st_menu_holder').fadeOut('fast');
                    }
                });
            };
        });*!/
    };*/

    $(document).ready(function() { // after loading the DOM
	/***************************************************
    Flickr
    ***************************************************/
        /*
        if ($("div").is("#cbox")) {
            $('#cbox').jflickrfeed({
                limit: 10,
                qstrings: {
                    id: "52617155@N08"
                },
                itemTemplate: '<div class="oi_flickr_item">' +
                    '<a data-lightbox="roadtrip" href="{{image_b}}" title="{{title}}">' +
                    '<img src="{{image_s}}"/>' +
                    '</a>' +
                    '</div>'
            });
        }
	
	
	
        $('.oi_img_grid img').hover(function() {
            $(this).addClass('transition');

        }, function() {
            $(this).removeClass('transition');
        });
        */

        $(".lazy-unveil").unveil(200, function() {

            /*
            $(this).load(function () {
                this.style.opacity = 1;
            });
            */
        });


        $('.owl-carousel').owlCarousel({
            margin:10,
            loop:true,
            /*autoWidth:true,*/
            height:300,
            autoHeight:true,
            items:4,
            lazyLoad : true,

            autoplay:true,
            autoplayTimeout:4000,
            autoplayHoverPause:true,

            responsiveClass:true,
            responsive:{
                0:{
                    items:1,
                    nav:true
                },
                600:{
                    items:2,
                    nav:false
                },
                1000:{
                    items:3,
                    nav:true,
                    loop:false
                }
            }
        });  


        $('.oi_page_holder').css('visibility', 'hidden');
        // makes sure the whole site is loaded
        jQuery("#status").css('display', 'block');
        jQuery("#preloader").css('display', 'block');


        $('.oi_page_holder').css('visibility', 'visible');
        // will first fade out the loading animation
        $("#status").fadeOut("slow");
        // will fade out the whole DIV that covers the website.
        $("#preloader").fadeOut("slow");


        function isEmail(email) {
            var regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            return regex.test(email);
        }

        $("#ajax-contact-form").submit(function(e) {

            e.preventDefault();

            // this points to our form
            var str = $(this).serialize(); // Serialize the data for the POST-request

            if($.trim($("#nome").val())=='') {

                swal({
                    title: "Attenzione!", text: "Il campo nome è obbligatorio", type: "warning",
                    showCancelButton: false, confirmButtonColor: "#DD6B55", confirmButtonText: "Ok", closeOnConfirm: true
                    },
                    function(){ $("#nome").trigger("focus"); return false;
                });
            }
            else
                if($.trim($("#email").val())=='') {
                    swal({
                            title: "Attenzione!", text: "Il campo email è obbligatorio", type: "warning",
                            showCancelButton: false, confirmButtonColor: "#DD6B55", confirmButtonText: "Ok", closeOnConfirm: true
                        },
                        function(){ $("#email").trigger("focus"); return false;
                        });
                }
                else
                    if(!isEmail($("#email").val())) {

                        swal({
                                title: "Attenzione!", text: "Formato email non valido", type: "warning",
                                showCancelButton: false, confirmButtonColor: "#DD6B55", confirmButtonText: "Ok", closeOnConfirm: true
                            },
                            function(){ $("#email").trigger("focus"); return false;
                            });

                            //alert("Formato email non valido"); $("#email").trigger("focus"); return false;
                    }
                    else
                    if(! $("#privacy:checked")) {

                        swal({
                                title: "Attenzione!", text: "Bisogna acconsentire alle Privacy Policy e Cookies Policy", type: "warning",
                                showCancelButton: false, confirmButtonColor: "#DD6B55", confirmButtonText: "Ok", closeOnConfirm: true
                            },
                            function(){ $("#email").trigger("focus"); return false;
                            });

                    }
                    else
                        if($.trim($("#oggetto_della_richiesta").val())=='') {

                            swal({
                                    title: "Attenzione!", text: "Il campo oggetto è obbligatorio", type: "warning",
                                    showCancelButton: false, confirmButtonColor: "#DD6B55", confirmButtonText: "Ok", closeOnConfirm: true
                                },
                                function(){ $("#oggetto_della_richiesta").trigger("focus"); return false;
                                });

                            //alert("Il campo oggetto è obbligatorio"); $("#oggetto_della_richiesta").trigger("focus"); return false;
                    }
                    else
                        if($.trim($("#messaggio").val())=='') {

                            swal({
                                title: "Attenzione!", text: "Il campo messaggio è obbligatorio", type: "warning",
                                showCancelButton: false, confirmButtonColor: "#DD6B55", confirmButtonText: "Ok", closeOnConfirm: true
                            },
                            function(){ $("#messaggio").trigger("focus"); return false;
                            });

                            //alert("Il campo messaggio è obbligatorio"); $("#messaggio").trigger("focus"); return false;
                        }
            else {

                    var result = '';
                    $.ajax({

                        type: "POST",
                        url: '/send_contact_message',
                        data: str,
                        success: function (response) {
                            //console.log(response);
                            
                            if (response.status == true) {
                                result = '<div class="alert alert-success">Messaggio inviato con successo, grazie per averci contattato.</div>';
                                $("#fields").hide();
                            } else {
                                result = "Si &egrave; verificato un errore durante l'invio del messaggio, si prega di riprovare pi&ugrave; tardi.";
                            }
                            $("#note").html(result);
                        },
                        error: function(XMLHttpRequest, textStatus, errorThrown) {
                            var errors = JSON.parse(XMLHttpRequest.responseText);

                            var blkstr = $.map(errors, function(val,index) {
                                var str = val;
                                return str;
                            }).join("<br/>")

                            swal({
                                    title: "Attenzione!", text: blkstr, type: "warning",
                                    showCancelButton: false,
                                    html: true,
                                    confirmButtonColor: "#DD6B55", confirmButtonText: "Ok", closeOnConfirm: true
                                });

                            console.log(blkstr);
                        }

                    });
                    return false;
            }
        });


        /*var cookiebar = new Cookiebar({
            debug: 0,
            content: {
                description: "Questo sito utilizza i cookie. ",
                link: "Clicca qui per maggiori informazioni",
                href: "http://demo.ricordidivita.it/cookies.html",
                button: "Ho capito e accetto",
            }
        });*/

    });




    $("#province-list").listnav({

        initLetter: '',        // filter the list to a specific letter on init ('a'-'z', '-' [numbers 0-9], '_' [other])
        includeAll: true,      // Include the ALL button
        includeOther: false,    // Include a '...' option to filter non-english characters by
        includeNums: false,     // Include a '0-9' option to filter by
        flagDisabled: true,    // Add a class of 'ln-disabled' to nav items with no content to show
        removeDisabled: true, // Remove those 'ln-disabled' nav items (flagDisabled must be set to true for this to function)
        allText: 'Tutte',        // set custom text in navbar to ALL button
        noMatchText: 'Nessuna provincia con questa lettera.', // set custom text for nav items with no content to show
        //showCounts: true,      // Show the number of list items that match that letter above the mouse
        //dontCount: '',        // A comma separated list of selectors you want to exclude from the count function (numbers on top of navigation)
        cookieName: 'selected_province',      // Set this to a string to remember the last clicked navigation item requires jQuery Cookie Plugin ('myCookieName')
        onClick: null,         // Set a function that fires when you click a nav item. see Demo 5
        prefixes: [],          // Set an array of prefixes that should be counted for the prefix and the first word after the prefix ex: ['the', 'a', 'my']
        filterSelector: ''

    });




    /*
    $('.oi_xs_menu').click(function() {
        $('.post-categories').toggle();
    });
    */

    /*
    $('#oi_container img').removeAttr('height');
    $('#oi_container img').removeAttr('width');
    */

    /*
    $('.wp-caption').removeAttr('style');
    $('table:not(#wp-calendar)').addClass('table table-striped table-bordered');
    */
    

});